import React from 'react';
import { FaSquareInstagram } from 'react-icons/fa6';
import { FaFacebookSquare } from 'react-icons/fa';
import { logo } from '../assets/index';

const DisplayError = () => {
  return (
    <div className='flex flex-col justify-center items-center h-screen text-primaryBlue text-center gap-6 px-2'>
      <img src={logo} alt='Sizz Design Logo' className='w-20 md:w-32 mb-4' />
      <p>
        Ups... de momento o website não está disponível. Por favor, tente mais tarde.
      </p>
      <p>
        Entretanto veja o nosso trabalho nas redes sociais:
      </p>
      <div className='flex flex-col justify-center z-10 mb-10 md:mb-0'>
        <ul className='flex text-4xl md:text-5xl gap-2'>
          <a
            href='https://www.instagram.com/sizz.design/'
            target='_blank'
            rel='noreferrer'
          >
            <li className='text-primaryLighter hover:text-primaryBlue'>
              <FaSquareInstagram />
            </li>
          </a>
          <a
            href='https://www.facebook.com/sizzdesign/'
            target='_blank'
            rel='noreferrer'
          >
            <li className='text-primaryLighter hover:text-primaryBlue'>
              <FaFacebookSquare />
            </li>
          </a>
        </ul>
      </div>
    </div>
  );
};

export default DisplayError;
